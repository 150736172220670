import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo"
import Section from "../../components/Section"
import SectionBanner from "../../components/SectionBanner";
import SectionOverview from "../../components/SectionOverview"
import { useStaticQuery, graphql, Link } from "gatsby"
import DecorativeLink from "../../components/DecorativeLink"


function ContractVehiclesList() {
  const data = useStaticQuery(graphql`
  {
    allContractVehiclesJson {
      nodes {
        title
        jsonId
      }
    }
  }
  `)

  const contractVehicles = data.allContractVehiclesJson.nodes
  const customLinks = [{
    "title": "GSA Multiple Award Schedule (MAS)",
    "link": "https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=GS-00F-439GA&contractorName=BIXAL+SOLUTIONS+INCORPORATED&executeQuery=YES"
  }]

  const allContractVehicles = [...contractVehicles, ...customLinks]
  const inlineListStyle = {
    listStyle: "initial",
    paddingInlineStart: "initial",
    padding: "initial",
    margin: "initial",
  }

  return (<ul
    style={{
      listStyleType: 'initial',
      paddingInlineStart: '20px',
      margin: 'initial',
    }}
  >
    {allContractVehicles.map((node, i) => {

      return (
        <li key={i} style={inlineListStyle}>
          {!node.jsonId ?
            <DecorativeLink href={node.link}>
              {node.title}
            </DecorativeLink> :
            <DecorativeLink>
              <Link to={`/contract-vehicles/${node.jsonId}`}>
                {node.title}
              </Link>
            </DecorativeLink>
          }
        </li>
      )
    })}
  </ul>
  )

}


export default function ContractVehiclesLanding() {

  return (
    <Layout id="contract-vehicles-landing">
      <Seo
        title="Contract Vehicles"
        description="We Work in the Federal Space."
        relPath={'/contract-vehicles/'}
      />
      <div className="page--contract-vehicles">
        <SectionBanner
          id={'hero'}
          bgImage={'contract-vehicles/hero-contract-vehicles.jpg'}
          bgTextShade={true}
          title="Contract Vehicles"
        />
        <Section id="where-we-work" constrain indent spacing={30}>
          <SectionOverview align={'left'} title={''} divider={true}>
            <h2>A Focus on Federal</h2>
          </SectionOverview>
          <p>
            Bixal partners with federal agencies and organizations for two
            reasons: We believe that everyone has the right to effective
            government, and we believe that our government has the potential to
            deliver on its promise of promoting the general welfare of all
            people. Helping federal agencies create tangible change—by providing
            an agile, people-first approach to fixing complex organizational
            issues—is what we do best. Our dedicated teams work with
            stakeholders to deliver powerful data-driven customer experiences
            through digital and communications solutions to real-life problems.
          </p>
        </Section>
        <Section id="capabilities" constrain indent spacing={0}>
          <SectionOverview align={'left'} title={''} divider={true}>
            <h2>Capabilities</h2>
          </SectionOverview>
          <p>
            There’s no better way to reach communities and transform
            organizations than with effective technology and digital
            communications. The services we offer start with meaningful dialogue
            to gain an understanding of user needs and institutional goals and
            end with effective, customer-centric solutions to even the most
            intricate of problems.
          </p>
        </Section>
        <Section id="contract-vehicles" constrain indent spacing={30}>
          <SectionOverview align={'left'} title={''} divider={true}>
            <h2>Contract Vehicles</h2>
          </SectionOverview>
          <p>
            Government agencies can seek our services through several contract
            vehicles, which are designed to provide an efficient and effective
            way to procure a range of services—including IT, marketing and
            communications, administrative management, consulting services, and
            an array of other professional support products and services—for all
            contract types.
          </p>
          <div
            style={{
              marginBottom: 80,
              maxWidth: 600,
            }}
          >
            <ContractVehiclesList></ContractVehiclesList>
          </div>
        </Section>
      </div>
    </Layout>
  )
}